<template>
  <div class="container cl17 bgf fs14 flex flex-direction-column">
    <van-sticky>
      <div class="imitate-search-box">
        <div class="imitate-input" @click="handleSearch">
          <van-icon
            :name="require('@/assets/img/common/search.svg')"
            color="#999"
            size="16"
          />
          <span class="imitate-placehodor">搜索</span>
        </div>
      </div>
    </van-sticky>
    <div class="container-wrapper flex-1 flex">
      <div class="menu-wrapper flex-s0" ref="menuScroll">
        <div class="bgf">
          <van-sidebar v-model="activeMenu">
            <van-sidebar-item
              v-for="(item, index) in customerLabel"
              :key="index"
              :title="item.name"
              :class="{
                'menu-item-last': activeMenu - 1 === index,
                'menu-item-next': activeMenu + 1 === index
              }"
              :id="'menu' + index"
              @click="selectMenu(index)"
            />
          </van-sidebar>
        </div>
      </div>
      <div class="label-wrapper pbs flex-1" ref="labelScroll">
        <div
          v-for="(item, index) in customerLabel"
          :key="index"
          :id="'label-cell' + index"
          class="label-cell"
        >
          <div class="fw500 mb8 pt12">{{ item.name }}</div>
          <div class="">
            <div
              v-for="label in item.list"
              :key="label.goods_id"
              class="label-item mr8"
            >
              <div class="flex pl4 pt12 pb12">
                <img
                  :src="label.order_list_image"
                  alt=""
                  class="img-class flex-s0 flex-g0"
                />
                <div class="ml12 flex-s1 flex-g1">
                  <div class="fs14 cl17 fw400 txt-line-1">
                    {{ label.goods_title }}
                  </div>
                  <div class="flex flex-justify-between">
                    <div>
                      <div class="fs12 fw400 cl99">
                        可售：{{ label.inventory_total }}件
                      </div>
                      <div class="mt4">
                        <span class="cl34">￥</span>
                        <span class="fs20 fw500 cl34">
                          {{ label.sales_price }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-g0 flex-s0 mt18">
                  <van-button
                    round
                    type="primary"
                    size="small"
                    :disabled="label.inventory_total == 0"
                    @click="handleShare(label)"
                  >
                    发送
                  </van-button>
                </div>
              </div>
              <div class="bdb"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Toast } from 'vant'
import { defineProps, nextTick, onMounted, reactive, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { sendChatMessageAllType } from '@/utils/nwechat.js'
import {
  getCategoryGoodsList,
  getCategoryList
} from '@/service/checkQr.service'

const route = useRoute()
const router = useRouter()
const memberId = ref('') // 用户id
const activeMenu = ref(0) // 选中的标签类索引
const customerLabel = ref([]) // 客户标签
const goodsList = ref([]) // 商品数据
const menuScroll = ref(null) // 菜单栏ref
const labelScroll = ref(null) // 内容区域ref
const labelCellHeights = ref([]) // 标签Html高度
const loading = ref(false) // 事件loading

const handleShare = async (item) => {
  let title = item.xcx_share_title
  let imgUrl = item.xcx_share_image
  let page = `pages/goodsDetail/index.html?goods_id=${item.goods_id}&is_share=1&member_id=${memberId.value}`

  let shareObj = {
    appid: 'wxbecd55f783f6a0cd', //小程序的appid 群店:wxbecd55f783f6a0cd; aosifen: wx754859a6c762e762
    title,
    imgUrl, //小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
    page //小程序消息打开后的路径，注意要以.html作为后缀，否则在微信端打开会提示找不到页面
  }
  // console.log('发送前参数', shareObj)
  await sendChatMessageAllType('miniprogram', {
    miniprogram: { ...shareObj }
  })
}

// 跳转搜索页
const handleSearch = () => {
  let userId = memberId.value
  router.push({
    path: '/b2cGoods-search',
    query: {
      userId
    }
  })
}

onMounted(() => {
  //   initData()
  getLabelList()
  initScroll()
})
// 添加labelScroll的scroll事件
const initScroll = () => {
  labelScroll.value.addEventListener('scroll', (e) => {
    const scrollY = labelScroll.value.scrollTop
    // console.log('222222222222222222', labelCellHeights)
    labelCellHeights.value.forEach(async (item, i) => {
      let height1 = labelCellHeights.value[i]
      let height2 = labelCellHeights.value[i + 1]
      let height3 =
        labelCellHeights.value[labelCellHeights.value.length - 1] -
        labelScroll.value.clientHeight
      //   console.log('33333', scrollY >= height1, labelCellHeights.value[i + 1])
      if (scrollY >= height1 && scrollY < height2) {
        if (activeMenu.value < i || scrollY < height3) {
          if (
            height3 - scrollY < 200 &&
            customerLabel.value[i + 1] &&
            !customerLabel.value[i + 1].finished
          ) {
            // console.log('1111', customerLabel.value[i + 1])
            customerLabel.value[i + 1].finished = true
            let params = {}
            params.goods_category_id =
              customerLabel.value[i + 1].goods_category_id
            let res = await getCategoryGoodsList(params)
            customerLabel.value[i + 1].list = res.data
            nextTick(() => {
              calulateHeight()
            })
          }
          //   console.log('1212121', scrollY, height3, labelCellHeights.value)
          if (activeMenu.value !== i) {
            // console.log('2222222', i, labelCellHeights.value)
            document.getElementById('menu' + i).scrollIntoView(true)
          }
          activeMenu.value = i
        }
      }
    })
  })
}

// 获取label-cell标签的可视高度
const calulateHeight = () => {
  let foodlist = labelScroll.value.getElementsByClassName('label-cell')
  console.log(
    'labelScroll',
    labelScroll.value,
    labelScroll.value.getElementsByClassName('label-cell')
  )
  console.log('foodlist', foodlist)
  let height = 0
  const data = []
  data.push(height)
  for (let i = 0; i < foodlist.length; i++) {
    height += foodlist[i].clientHeight
    data.push(height)
  }
  //   foodlist.forEach((item) => {
  //     height += item.clientHeight
  //     data.push(height)
  //   })
  labelCellHeights.value = data
}
// 选择标签类, 滑动到该类的标签
const selectMenu = async (i) => {
  activeMenu.value = i
  let params = {}
  params.goods_category_id = customerLabel.value[i].goods_category_id
  let res = await getCategoryGoodsList(params)
  customerLabel.value[i].list = res.data
  nextTick(() => {
    calulateHeight()
    document.getElementById('label-cell' + i).scrollIntoView(true)
  })
}

watch(
  () => activeMenu.value,
  (val) => {
    console.log('valvalval', val)
  }
)

// 获取标签组列表
const getLabelList = async () => {
  try {
    Toast.loading({
      message: '加载中',
      forbidClick: true,
      duration: 0
    })
    const { data } = await getCategoryList()
    Toast.clear()
    customerLabel.value = data
    let params = {}
    params.goods_category_id = data[0].goods_category_id
    let res = await getCategoryGoodsList(params)
    customerLabel.value[0].list = res.data
    memberId.value = customerLabel.value[0].member_id
    nextTick(() => {
      setTimeout(() => {
        calulateHeight()
      }, 300)
    })
  } catch (error) {
    Toast.clear()
  }
}
</script>

<style lang="less" scoped>
@import './goods.less';
:deep(.van-button--small) {
  width: 56px !important;
  font-size: 14px !important;
}
</style>
